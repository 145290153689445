import React, { useState, useEffect } from "react";
import queryString from 'query-string';
import Nav from "./Nav";
import _ from 'underscore';

export default () => {

    const queryParams = queryString.parse(window.location.search);
    if (queryParams.user) {
        localStorage.setItem('user', queryParams.user);
    }

    if (queryParams.key) {
        localStorage.setItem('key', queryParams.key);
    }

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [input, setInput] = useState("");
    const [data, setData] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isReady, setIsReady] = useState(!queryParams.user);

    const [user, setUser] = useState(
        localStorage.getItem('user') ? atob(localStorage.getItem('user')) : ''
    );

    const [key, setKey] = useState(
        localStorage.getItem('key') || ''
    );

    const logout = () => {
        localStorage.clear();
        setKey(null);
        setUser(null);
        setIsLoggedIn(false);
        setData(null);
    }

    const handleEmail = event => {
        setError();
        setMessage();
        event.preventDefault();
        if (!input) {
            return;
        }
        setLoading(true);
        fetch("/.netlify/functions/api", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                operation: 'sendEmail',
                user: input
            }),
        })
            .then(response => response.json())
            .then(json => {
                setLoading(false);
                if (json.error) {
                    logout();
                    setError(json.error);
                    setMessage();
                } else {
                    setError();
                    setMessage(json.message);
                }
            });
    }

    const getData = event => {
        setLoading(true);
        fetch("/.netlify/functions/api", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                operation: 'getData',
                user,
                key,

            }),
        })
            .then(response => response.json())
            .then(json => {
                if (json.error) {
                    setError(json.error);
                    setKey(null);
                    setUser(null);
                    setIsLoggedIn(false);
                } else {
                    setError();
                    setData(json);
                    setIsLoggedIn(true);
                    const queryParams = queryString.parse(window.location.search);
                    if (queryParams.user || queryParams.key) {
                        window.history.pushState("","BlueLocker", '/');
                    }
                }
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                logout();
                setError('An error occurred. Please try again in 60 seconds.');
            })
        ;
    }

    const initialize = () => {
        if (key && user && !isLoggedIn && !loading) {
            setLoading(true);
            setIsReady(true);
            getData();
        }
    }


    useEffect(_.debounce(initialize, 1000));

    return (
        <>
            <Nav isLoggedIn={isLoggedIn} user={user} logout={logout} />
            {loading &&
            <div className="loader-widget">
                <div className="duo duo1">
                    <div className="dot dot-a"></div>
                    <div className="dot dot-b"></div>
                </div>
                <div className="duo duo2">
                    <div className="dot dot-a"></div>
                    <div className="dot dot-b"></div>
                </div>
            </div>
            }
            {!loading && !isLoggedIn && !message && isReady &&
            <div className='container'>
                <section className="section pane">
                    <div className="container">
                        <form onSubmit = { e => { e.preventDefault(); handleEmail(); }} >
                            <div className="form-overview">
                                Access your locker with your email address:
                            </div>
                            <div className="group">
                                <input autoFocus type="text" required placeholder="Email Address" onChange={e => setInput(e.target.value)} />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                            </div>
                            <button className="button is-primary" type="submit" onClick={handleEmail}>Get Access</button>
                        </form>

                    </div>
                </section>
            </div>
            }
            {(error || message) &&
            <div className='container'>
                <div className='pane'>
                    <div className='notifications'>
                        {error &&
                        <div className='notification is-danger'>
                            {error}
                        </div>
                        }
                        {message &&
                        <div className='notification is-success'>
                            {message}
                        </div>
                        }
                    </div>
                </div>
            </div>
            }

            {data && data.Files &&
            <div className='container'>
                <div className="columns is-centered">
                    <div className="column">

                        <div className='pane'>
                            <h2 className="title is-2">
                                Your Files
                            </h2>
                            {
                                data.Files.length ?
                                <table className='table'>
                                    <thead>
                                    <tr>
                                        <th>Exercise</th>
                                        <th>Role</th>
                                        <th>File</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {data.Files.map((file, i) =>
                                        <tr key={i}>
                                            <td>
                                                {file['Exercise']}
                                            </td>
                                            <td>
                                                {file['Role']}
                                            </td>
                                            <td>
                                                <a className="file-line" target="_blank" href={file['File']}>Download</a>
                                            </td>
                                        </tr>
                                    )}

                                    </tbody>
                                </table>
                                :
                                <em>None</em>
                            }
                        </div>
                    </div>
                </div>
            </div>
            }


            {data && data.Feedback &&
                /*
                Date
                Exercise
                Feedback Author
                Feedback Recipient
                What worked well?
                Suggestions for next time? */
            <div className='container'>
                <div className="columns is-centered">
                    <div className="column">

                        <div className='pane'>
                            <h2 className="title is-2">
                                Your Feedback
                            </h2>

                                { data.Feedback.length ?
                                    data.Feedback.map( (feedback, i) =>
                                        <div className="card" key={i}>
                                            <header className="card-header">
                                                <div className="card-header-title">
                                                    <div className="title is-4">
                                                        Written by {feedback['Feedback Author']} in {feedback['Exercise']}
                                                    </div>
                                                    <div className="subtitle is-6">Date: {feedback['Date']}</div>
                                                </div>
                                            </header>
                                            <div className="card-content">
                                                <div className="columns">
                                                    <div className="column">
                                                        <p className="title is-5">What worked well?</p>
                                                        <p dangerouslySetInnerHTML={{__html: feedback['What worked well?'].replace(/\n/g,'<br/>')}} />
                                                    </div>

                                                    <div className="column">
                                                        <p className="title is-5">Suggestions for next time?</p>
                                                        <p dangerouslySetInnerHTML={{__html: feedback['Suggestions for next time?'].replace(/\n/g,'<br/>')}} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                    :
                                    <em>No feedback at this time</em>
                                }
                        </div>
                    </div>
                </div>
            </div>
            }

        </>
    );
}
