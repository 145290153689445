import React from "react";


import Hub from './Hub';

const App = () => {
  return (
    <>
        <Hub />
    </>
  );
};

export default App;
