import React from "react";
export default function Nav(props) {
    return (
        <div className='container'>
            <div className='pane brand-bar'>
                <a className='home-link' href='/'>
                    <div className='logo-container'>
                        <div className="logo-icon">
                            <img src='/locker-icon.png' />
                        </div>
                        <div className='logo-name'>BlueLocker</div>
                    </div>
                </a>
                { props.isLoggedIn &&
                <div className='logout-container level'>
                    <div className='level-left'>
                        <div className='level-item'>
                            You are currently logged in as {props.user}
                        </div>
                        <div className='level-item'>
                            <a className='logout-link button is-primary is-small' onClick={props.logout}>Log Out</a>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    );
}